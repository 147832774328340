import axios from "axios";

const { createContext, useState, useContext, useEffect, useCallback } = require("react");

const Context = createContext();

const Provider = ({ children, value }) => {
  const [bootstrap, setBootstrap] = useState(null);

  const fetchBootstrapData = useCallback(async () => {
    const response = await axios.get(
      `/api/bootstrap`,
    );

    setBootstrap(response.data.bootstrap);
  }, []);

  useEffect(() => {
    if (bootstrap === null) {
      fetchBootstrapData()
        .catch(console.error);
    }
  }, [bootstrap])

  const exposed = {
    bootstrap: {
      ...bootstrap,
      ...value,
    },
    setBootstrap,
  };

  return (
    <Context.Provider value={exposed}>
      {children}
    </Context.Provider>
  );
};

export const useBootstrap = () => useContext(Context);

export default Provider;
