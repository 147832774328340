import { createPortal } from 'react-dom';
import styles from './flash.module.scss';
import { useFlash } from '../../context/flash';
import { useState, useEffect } from 'react';
import Flash from './Flash';

const FlashContainer = () => {
  const [loaded, setLoaded] = useState(false);
  const { messages, dismissMessage } = useFlash();

  useEffect(() => {
    setLoaded(true);
  }, []);

  return loaded && createPortal(
    <div className={styles.flashContainer}>
      {messages.map((msg) => (
        <Flash
          key={msg.id}
          title={msg.title}
          message={msg.message}
          type={msg.type}
          fade={msg.timeout}
          onClose={() => dismissMessage(msg.id)}
        />
      ))}
    </div>,
    document.getElementById('flash-root'),
  );
};

export default FlashContainer;

