import { useRouter } from "next/router";

const { createContext, useState, useContext, useEffect, useCallback } = require("react");

const Context = createContext();

const featuresConfig = [
  {
    name: 'v2',
    defaultValue: true,
  },
];

const Provider = ({ children, value=[] }) => {
  const [features, setFeatures] = useState(
    featuresConfig
      .map(feat => {
        if (feat.defaultValue === true)
          return feat.name
      })
      .filter(feat => typeof feat !== 'undefined')
  );
  const router = useRouter();
  const { pathname, query } = router;
  const [params, setParams] = useState(new URLSearchParams(query));

  useEffect(() => {
    value.map(feat => {
      if (feat.value) {
        setFeatures((features) => [...features, feat.name])
      }
      else {
        setFeatures((features) => features.filter(f => f !== feat.name))
      }
    });
  }, [value]);

  useEffect(() => {
    setParams(new URLSearchParams(query));
  }, [query]);

  useEffect(() => {
    let updatedFeatures = [...features];
    params.forEach((_, paramName) => {
      const feature = featuresConfig.find(({ name }) => name === paramName);
      const existingFeature = features.find(name => name === paramName);

      if (feature && !existingFeature) {
        updatedFeatures.push(paramName);
      }
    });

    if (features.toString() != updatedFeatures.toString()) {
      setFeatures(updatedFeatures);
    };

  }, [params, features]);

  const isFeatureEnabled = useCallback((featName) => {
    return typeof features.find(feat => feat === featName) !== 'undefined';
  }, [features]);

  const toggleFeature = useCallback((featName) => {
    if (params.has(featName)) {
      const index = features.indexOf(featName);
      if (index > -1) {
        features.splice(index, 1);
      }
      params.delete(featName);
    }
    else {
      params.append(featName, true);
      features.push(featName);
    }

    setFeatures(features);
    router.replace(
      { pathname, query: params.toString() },
      undefined, 
      { shallow: true }
    );
  }, [features, params, router, setFeatures]);

  const exposed = {
    features: [
      ...features,
    ],
    setFeatures,
    isFeatureEnabled,
    toggleFeature,
  };

  return (
    <Context.Provider value={exposed}>
      {children}
    </Context.Provider>
  );
};

export const useFeatures = () => useContext(Context);

export default Provider;
