import axios from "axios";
import { buildCompanyFormData, buildLeadershipFormData, buildSensitiveDocFormData } from "../company";
import { isSessionError, pluralise } from "../util";

export const getCompany = async (api, id) => {
  let company;
  const { host, authHeader } = api;

  const response = await axios.get(
    `${host}/companies/${id}`,
    authHeader,
  );

  company = response.data.company;

  return company;
};

export const getAllCompanies = async (api) => {
  const { host, authHeader } = api;

  const response = await axios.get(
    `${host}/all_companies`,
    authHeader,
  );

  const { companies } = response.data;

  return companies;
};

export const searchCompaniesByName = async (api, name) => {
  const { host, authHeader } = api;

  const response = await axios.post(
    `${host}/companies/search?name=${name}`,
    null,
    authHeader,
  );

  const { companies } = response.data;

  return companies;
};

export const getCompanyBySlug = async (apiHost, authHeader, slug) => {
  const response = await axios.post(
    `${apiHost}/public/companies/search?slug=${slug}`,
    null,
    authHeader,
  );

  const { company } = response.data;

  return company;
};


export const createCompany = async (company, message, loadingFn, router, flash) => {
  const { addMessage, addErrorMessage } = flash;
  const { authHeader, apiHost } = company;

  try {
    loadingFn(true);

    await axios.post(
      `${apiHost}/companies`,
      {
        company: {
          name: company.name,
          website: company.website,
          year_founded: company.year_founded,
          sector: company.sectors,
          created_user_role: company.created_user_role,
          city: company.city,
          country: company.country,
          address: `${company.city} ${company.country}`,
        },
      },
      authHeader
    );
      
    router.push('/managed-companies');
    addMessage(`Company ${company.name} Created`, message);
  }
  catch(e) {
    loadingFn(false);
    addErrorMessage('Company Creation Failed', 'An unexpected error occurred');
  }
};



export const updateCompany = async (api, companyId, fields, message, loadingFn, router, flash) => {
  const { addMessage, addErrorMessage } = flash;
  const { authHeader, host } = api;

  loadingFn(true);

  try {
    const result = await axios.put(
      `${host}/companies/${companyId}`,
      buildCompanyFormData(fields),
      authHeader,
    );

    loadingFn(false);
    const { slug } = result.data.company;
    if (router) {
      const query = router.query.v2 ? '?v2=true': null;
      router.push(`/${slug}${query || ''}`, undefined, { scroll: false });
    }
    addMessage('Company Updated', message || 'Changes successfully applied.');

    return true;
  }
  catch(e) {
    loadingFn(false);
    if (!isSessionError(e, router, addErrorMessage)) {
      const errorMessage = e.response?.data?.errors?.base || 'Something went wrong.';
      addErrorMessage('Company Update Failed', errorMessage);
    }

    return false;
  }
};


export const addCapitalRaise = async (
  api,
  company,
  date,
  preMoney,
  amount,
  loadingFn,
  flash
) => {
  const { addMessage, addErrorMessage } = flash;
  const { host, authHeader } = api;
  loadingFn(true);

  try {
    await axios.post(
      `${host}/companies/${company.id}/capital_raises`,
      {
        date_raised: date,
        pre_money_amount: preMoney,
        amount,
      },
      authHeader
    );

    loadingFn(false);
    addMessage("Added New Capital Raise", "Changes successfully applied.");

    return true;
  } catch (e) {
    loadingFn(false);
    addErrorMessage("Add New Capital Raise Failed", "Something went wrong.");
    return false;
  }
};

export const updateCapitalRaise = async (
  api,
  company,
  raiseId,
  date,
  preMoney,
  amount,
  loadingFn,
  flash
) => {
  const { addMessage, addErrorMessage } = flash;
  const { host, authHeader } = api;
  loadingFn(true);

  try {
    await axios.put(
      `${host}/companies/${company.id}/capital_raises/${raiseId}`,
      {
        date_raised: date,
        pre_money_amount: preMoney,
        amount,
      },
      authHeader
    );

    loadingFn(false);
    addMessage("Updated Capital Raise", "Changes successfully applied.");

    return true;
  } catch (e) {
    loadingFn(false);
    addErrorMessage("Update Capital Raise Failed", "Something went wrong.");
    return false;
  }
};

export const removeCapitalRaise = async (
  api,
  company,
  raiseId,
  loadingFn,
  flash
) => {
  const { addMessage, addErrorMessage } = flash;
  const { host, authHeader } = api;
  loadingFn(true);

  try {
    await axios.delete(
      `${host}/companies/${company.id}/capital_raises/${raiseId}`,
      authHeader
    );

    loadingFn(false);
    addMessage("Deleted Capital Raise", "Changes successfully applied.");

    return true;
  } catch (e) {
    loadingFn(false);
    addErrorMessage("DeletedCapital Raise Failed", "Something went wrong.");
    return false;
  }
};

export const addLeadership = async (api, company, fields, message, loadingFn, router, flash) => {
  const { addMessage, addErrorMessage } = flash;
  const { authHeader, host } = api;

  loadingFn(true);

  try {
    await axios.post(
      `${host}/companies/${company.id}/leaderships`,
      buildLeadershipFormData(fields),
      authHeader,
    );

    loadingFn(false);
    const { slug } = company;
    router.push(`/${slug}/manage-team`, undefined, { scroll: false });
    addMessage('Added New Leadership', message || 'Changes successfully applied.');

    return true;
  }
  catch(e) {
    loadingFn(false);
    if (!isSessionError(e, router, addErrorMessage))
      addErrorMessage('Add New Leadership Failed', 'Something went wrong.');

    return false;
  }
};

export const updateLeadership = async (api, company, fields, message, loadingFn, router, flash) => {
  const { addMessage, addErrorMessage } = flash;
  const { authHeader, host } = api;

  loadingFn(true);

  // TODO: direct to api
  try {
    await axios.put(
      //`/api/companies/${company.id}/leaderships?leadershipId=${fields.id}`,
      `${host}/companies/${company.id}/leaderships/${fields.id}`,
      buildLeadershipFormData(fields),
      authHeader,
    );

    loadingFn(false);
    const { slug } = company;
    router.push(`/${slug}/manage-team`, undefined, { scroll: false });
    addMessage('Updated Leadership', message || 'Changes successfully applied.');

    return true;
  }
  catch(e) {
    loadingFn(false);
    if (!isSessionError(e, router, addErrorMessage))
      addErrorMessage('Update Leadership Failed', 'Something went wrong.');

    return false;
  }
};

export const removeLeadership = async (company, leadershipId, message, loadingFn, router, flash) => {
  const { addMessage, addErrorMessage } = flash;
  loadingFn(true);

  try {
    await axios.delete(
      `/api/companies/${company.id}/leaderships?leadershipId=${leadershipId}`
    );

    loadingFn(false);
    const { slug } = company;
    router.push(`/${slug}/manage-team`, undefined, { scroll: false });
    addMessage('Removed Leadership', message || 'Changes successfully applied.');

    return true;
  }
  catch(e) {
    loadingFn(false);
    if (!isSessionError(e, router, addErrorMessage))
      addErrorMessage('Remove Leadership Failed', 'Something went wrong.');

    return false;
  }
};

export const uploadDocument = async (
  api,
  company,
  docSectionId,
  files,
  names,
  loadingFn,
  flash,
  onSuccess
) => {
  const { addMessage, addErrorMessage } = flash;
  const { id } = company;
  const { authHeader, host } = api;

  try {
    loadingFn(true);
    await axios.put(
      `${host}/companies/${id}/documents/${docSectionId}`,
      buildSensitiveDocFormData({ files, fileNames: names }),
      authHeader
    );

    // temp: reload entire company once document is uploaded
    await onSuccess();
    loadingFn(false);
    addMessage(
      `${pluralise(files.length, "Document", "Documents")} Uploaded`,
      "Changes successfully applied."
    );
    return true;
  } catch (e) {
    loadingFn(false);
    addErrorMessage("Document Upload Failed", "Something went wrong.");
    return false;
  }
};

export const deleteDocument = async (api, company, docSectionId, docId, loadingFn, flash, onSuccess) => {
  const { addMessage, addErrorMessage } = flash;
  const { id } = company;
  const { host, authHeader } = api;

  try {
    loadingFn(true);
    await axios.delete(
      `${host}/company/${id}/documents/${docSectionId}/${docId}`,
      authHeader,
    );

    await onSuccess();
    loadingFn(false);
    addMessage('Document Deleted', 'Changes successfully applied.');
    return true;
  }
  catch(e) {
    loadingFn(false);
    addErrorMessage('Document Delete Failed', 'Something went wrong.');
    return false;
  }
};


export const updateDocumentSection = async (
  api,
  company,
  docSectionId,
  name,
  visible,
  loadingFn,
  flash,
  onSuccess
) => {
  const { addMessage, addErrorMessage } = flash;
  const { id } = company;
  const { authHeader, host } = api;

  try {
    loadingFn(true);
    await axios.put(
      `${host}/companies/${id}/documents/${docSectionId}`,
      buildSensitiveDocFormData({ name, visible }),
      authHeader
    );

    // temp: reload entire company once document is uploaded
    await onSuccess();
    loadingFn(false);
    addMessage(
      `Section ${name} Updated`,
      "Changes successfully applied."
    );
    return true;
  } catch (e) {
    loadingFn(false);
    addErrorMessage("Section Update Failed", "Something went wrong.");
    return false;
  }
};

export const getAuthorisedUsers = async (apiHost, authHeader, company, flash) => {
  const { id } = company;
  const { addMessage, addErrorMessage } = flash;

  try {
    const response = await axios.get(
      `${apiHost}/companies/${id}/authorised_users`,
      authHeader,
    );

    return response.data.authorised_users;
  }
  catch(e) {
    console.log(e);
    addErrorMessage('Error Fetching Authorised Users', 'Something went wrong.');
  }
};

export const removeAuthorisedUser = async (apiHost, authHeader, company, authUserId, flash) => {
  const { id } = company;
  const { addMessage, addErrorMessage } = flash;

  try {
    await axios.delete(
      `${apiHost}/companies/${id}/authorised_users/${authUserId}`,
      authHeader,
    );

    addMessage('Removed Authorised User', `User was removed.`);
  }
  catch(e) {
    if (!isSessionError(e, router, addErrorMessage))
      addErrorMessage('Remove Authorised User Failed', 'Something went wrong.');
  }
};

export const authoriseUser = async (apiHost, authHeader, company, user, flash) => {
  const { id } = company;
  const { addMessage, addErrorMessage } = flash;

  try {
    await axios.post(
      `${apiHost}/companies/${id}/authorised_users`,
      {
        authorised_user: {
          user_id: user.id,
        },
      },
      authHeader
    );

    addMessage('User Authorised', `User ${user.full_name} was added.`);
  }
  catch(e) {
    addErrorMessage('Authorise User Failed', 'Something went wrong.');
  }
};

export const inviteNewUserToCompany = async (apiHost, authHeader, company, email, flash) => {
  const { addMessage, addErrorMessage } = flash;

  try {
    await axios.post(
      `${apiHost}/users/invite`,
      {
        invite_user: {
          email,
          company_id: company.id,
        },
      },
      authHeader
    );

    addMessage('User Invited', `User ${email} was invited.`);
  }
  catch(e) {
    addErrorMessage('Invite User Failed', 'Something went wrong.');
  }
};

export const sendGroupEmail = async (apiHost, authHeader, company, body, loadingFn, flash) => {
  const { id } = company;
  const { addMessage, addErrorMessage } = flash;

  try {
    loadingFn(true);
    await axios.post(
      `${apiHost}/notifications/${id}/send_all`,
      { send_all: { body } },
      authHeader
    );

    loadingFn(false);
    addMessage('Sent Email Successfully', `Your investors have been notified.`);
    return true;
  }
  catch(e) {
    loadingFn(false);
    addErrorMessage('Error Sending Group Email', 'Something went wrong.');
    return false;
  }
};

export const uploadShareCertificate = async (apiHost, authHeader, company, investor, shareCertificate, loadingFn, flash) => {
  const { addMessage, addErrorMessage } = flash;

  const body = new FormData();
  body.append("investor[share_certificate]", shareCertificate);

  try {
    loadingFn(true);
    await axios.put(
      `${apiHost}/companies/${company.id}/investors/${investor.id}`,
      body,
      authHeader
    );

    loadingFn(false);
    addMessage('Share Certificate Uploaded Successfully', 'Investor updated.');
    return true;
  }
  catch(e) {
    loadingFn(false);
    addErrorMessage('Share Certificate Upload Failed', 'Something went wrong.');
    return false;
  }
};

export const uploadCapTable = async (api, company, capTable, loadingFn, flash) => {
  const { addMessage, addErrorMessage } = flash;
  const { host, authHeader } = api;

  const body = new FormData();
  body.append("cap_table[file]", capTable);

  try {
    loadingFn(true);
    await axios.post(
      `${host}/companies/${company.id}/cap_table`,
      body,
      authHeader
    );

    loadingFn(false);
    addMessage('Cap Table Uploaded Successfully', 'Changes updated.');
    return true;
  }
  catch(e) {
    loadingFn(false);
    const errorMessage = e?.response?.data?.errors || 'Something went wrong.'
    addErrorMessage('Cap Table Upload Failed', errorMessage, 7000);
    return false;
  }
};

