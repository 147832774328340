import getConfig from "next/config";

export const getAuthHeader = (token) => {
  const { publicRuntimeConfig: { apiKey }} = getConfig();

  return {
    headers: {
      'X-auth': apiKey,
      'Authorization': `Bearer ${token}`,
    }
  };
};

