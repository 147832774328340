const { createContext, useState, useContext } = require("react");

const Context = createContext();

const Provider = ({ children, value }) => {
  const [navItems, setNavItems] = useState(value || []);

  const appendNavItem = (newNavItem) => {
    setNavItems([
      ...navItems,
      newNavItem,
    ]);
  };

  const exposed = {
    navItems,
    setNavItems,
    appendNavItem,
  };

  return (
    <Context.Provider value={exposed}>
      {children}
    </Context.Provider>
  );
};

export const useNavigation = () => useContext(Context);

export default Provider;
