import {
  updateUser,
} from "../lib/client/user";

const { createContext, useState, useContext, useCallback } = require("react");

const Context = createContext();

const Provider = ({ children, value }) => {
  const [user, setUser] = useState(null);
  const [interestedInCompanies, setInterestedInCompanies] = useState(null);

  const isLoggedIn = useCallback(() => {
    const userObj = value || user;

    return userObj &&
      userObj.full_name !== null &&
      typeof userObj.full_name !== 'undefined'|| false
  }, [value, user]);

  const clearUser = () => {
    setUser(null);
    setInterestedInCompanies(null);
  };

  const exposed = {
    user: {
      ...user,
      ...value,
    },
    interestedInCompanies,
    setUser,
    setInterestedInCompanies,
    isLoggedIn,
    updateUser,
    clearUser,
  };

  return (
    <Context.Provider value={exposed}>
      {children}
    </Context.Provider>
  );
};

export const useUser = () => useContext(Context);

export default Provider;
