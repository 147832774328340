const { createContext, useState, useContext } = require("react");
import { v4 as uuidv4 } from 'uuid';
import { FLASH_FAILURE, FLASH_SUCCESS } from '../components/flash/Flash';

const FLASH_TIMEOUT = 5000;

const Context = createContext();

const Provider = ({ children, value }) => {
  const [messages, setMessages] = useState(value || []);

  const appendMessage = (message) => {
    const messageId = uuidv4();

    setMessages((prevState) => {
      return [
        ...prevState,
        {
          id: messageId,
          ...message,
        }
      ];
    });

    setTimeout(() => {
      setMessages((prevState) => {
        return prevState.filter(msg => msg.id !== messageId);
      });
    }, message.timeout);
  };

  const addMessage = (title, message, timeout=FLASH_TIMEOUT) => {
    appendMessage({ message, title, type: FLASH_SUCCESS, timeout });
  };

  const addErrorMessage = (title, message, timeout=FLASH_TIMEOUT) => {
    appendMessage({ message, title, type: FLASH_FAILURE, timeout });
  };

  const dismissMessage = (id) => {
    setMessages((prevState) => {
      return prevState.filter(msg => msg.id !== id);
    });
  }

  const exposed = {
    messages,
    setMessages,
    addMessage,
    addErrorMessage,
    dismissMessage,
  };

  return (
    <Context.Provider value={exposed}>
      {children}
    </Context.Provider>
  );
};

export const useFlash = () => useContext(Context);

export default Provider;
