import styles from './flash.module.scss';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export const FLASH_SUCCESS = 'success';
export const FLASH_FAILURE = 'failure';

const Flash = ({ title, message, type, fade, onClose }) => {
  const classes = clsx([
    styles.flash,
    fade && styles.fade,
    styles[type],
  ]);

  return (
    <div
      className={classes}
      style={fade && {
        animationDelay: `${(fade/1000) - 2}s`
      }}
    >
      <div className={styles.indicator}>
      </div>
      <div className={styles.content}>
        <p className={styles.title}>{title}</p>
        {message && <p className={styles.message}>{message}</p>}
      </div>
      {onClose && (
        <div className={styles.action}>
          <button onClick={onClose}>Close</button>
        </div>
      )}
    </div>
  );
};

Flash.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  type: PropTypes.oneOf([
    FLASH_FAILURE,
    FLASH_SUCCESS
  ]),
};

Flash.defaultProps = {
  type: FLASH_SUCCESS,
};

export default Flash;

