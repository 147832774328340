import '../styles/globals.scss';
import '../styles/helpers.scss';
import '../components/inputs/selectOverrides.scss';
import '../components/newInputs/selectOverrides.scss';
import Head from 'next/head'; 
import UserProvider from '../context/user';
import FlashProvider from '../context/flash';
import FlashContainer from '../components/flash/FlashContainer';
import NavigationProvider from '../context/navigation';
import BootstrapProvider from '../context/bootstrap';
import FeaturesProvider from '../context/features';
import ApiProvider from '../context/api';
import CompanyProvider from '../context/company';
import DashboardProvider from '../context/dashboard';

const Noop = ({ children }) => <>{children}</>;

function MyApp({ Component, pageProps }) {
  const PageContextProvider = Component.provider || Noop;

  return (
    <>
      <Head>
        <title>Cap! Investment Portal</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
      </Head>
      <BootstrapProvider>
        <ApiProvider>
          <FeaturesProvider>
            <FlashProvider>
              <UserProvider>
                <NavigationProvider>
                  <DashboardProvider>
                    <CompanyProvider>
                      <PageContextProvider>
                        <Component {...pageProps} />
                        <FlashContainer />
                      </PageContextProvider>
                    </CompanyProvider>
                  </DashboardProvider>
                </NavigationProvider>
              </UserProvider>
            </FlashProvider>
          </FeaturesProvider>
        </ApiProvider>
      </BootstrapProvider>
    </>
  );
}

export default MyApp;
