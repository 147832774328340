import getConfig from "next/config";
import {getAuthHeader} from "../lib/client/auth";

const { createContext, useState, useContext, useEffect, useCallback, useReducer } = require("react");

const Context = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "updateApi":
      return {
        api: {
          ...state.api,
          [action.field]: action.value,
        },
      };
    case "clearState":
      return {
        api: {
          host: state.api.host,
          authHeader: null,
          token: null,
        },
      };

    default:
      throw new Error();
  }
};

const Provider = ({ children, value }) => {
  const { publicRuntimeConfig } = getConfig();
  const { apiHost } = publicRuntimeConfig;

  const [state, dispatch] = useReducer(reducer, {
    api: {
      host: apiHost,
      authHeader: null,
      token: null,
    },
  });

  const setHost = (host) => {
    dispatch({ type: 'updateApi', field: 'host', value: host });
  };
  const setAuthHeader = (authHeader) => {
    dispatch({ type: 'updateApi', field: 'authHeader', value: authHeader });
  };
  const setToken = (token) => {
    dispatch({ type: 'updateApi', field: 'token', value: token });
    setAuthHeader(getAuthHeader(token));
  };

  const clearApi = () => {
    dispatch({ type: 'clearState' });
  };

  const exposed = {
    ...state,
    dispatch,
    setHost,
    setAuthHeader,
    setToken,
    clearApi,
  };

  return (
    <Context.Provider value={exposed}>
      {children}
    </Context.Provider>
  );
};

export const useApi = () => useContext(Context);

export default Provider;
