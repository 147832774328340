
export const buildCompanyFormData = (fields) => {
  const {
    name,
    address,
    country,
    city,
    suburb,
    website,
    bio,
    logo,
    linkedIn,
    linkedin_url,
    insta,
    instagram_url,
    twitter,
    twitter_url,
    images,
    description,
    pitchbook,
    currency,
    year_founded,
  } = fields;
  const body = new FormData();
  (fields.hasOwnProperty('linkedIn') || fields.hasOwnProperty('linkedin_url')) && body.append("company[linkedin_url]", linkedIn || linkedin_url);
  (fields.hasOwnProperty('insta') || fields.hasOwnProperty('instagram_url')) && body.append("company[instagram_url]", insta || instagram_url);
  (fields.hasOwnProperty('twitter') || fields.hasOwnProperty('twitter_url')) && body.append("company[twitter_url]", twitter || twitter_url);
  fields.hasOwnProperty('name') && body.append("company[name]", name);
  fields.hasOwnProperty('address') && body.append("company[address]", address);
  fields.hasOwnProperty('country') && body.append("company[country]", country);
  fields.hasOwnProperty('city') && body.append("company[city]", city);
  fields.hasOwnProperty('suburb') && body.append("company[suburb]", suburb);
  fields.hasOwnProperty('website') && body.append("company[website]", website);
  fields.hasOwnProperty('bio') && body.append("company[bio]", bio);
  fields.hasOwnProperty('logo') && body.append("company[main_logo]", logo, logo.name);
  fields.hasOwnProperty('description') && body.append("company[description]", description);
  fields.hasOwnProperty('pitchbook') && body.append("company[pitchbook]", pitchbook);
  fields.hasOwnProperty('currency') && body.append("company[currency]", currency);
  fields.hasOwnProperty('year_founded') && body.append("company[year_founded]", year_founded);

  if (images) {
    if (images.length) {
      Array.from(images).forEach((image) => {
        body.append("company[images][]", image, image.name);
      });
    }
    else {
      body.append("company[images]", '');
    }
  }

  return body;
};

export const buildLeadershipFormData = (fields) => {
  const { name, title, linkedIn, twitter, bio, image } = fields;
  const body = new FormData();
  fields.hasOwnProperty('name') && body.append("leadership[full_name]", name);
  fields.hasOwnProperty('title') && body.append("leadership[title]", title);
  fields.hasOwnProperty('linkedIn') && body.append("leadership[linkedin_url]", linkedIn);
  fields.hasOwnProperty('twitter') && body.append("leadership[twitter_url]", twitter);
  fields.hasOwnProperty('bio') && body.append("leadership[bio]", bio);
  fields.hasOwnProperty('image') && image && body.append("leadership[image]", image, image.name);

  return body;
};

export const buildSensitiveDocFormData = (fields) => {
  const body = new FormData();

  if (fields.hasOwnProperty('files')) {
    Array.from(fields.files).forEach((file, i) => {
      body.append("si_document[files][]", file, fields.fileNames[i] || file.name);
    });
  }

  fields.hasOwnProperty('name') && body.append("si_document[name]", fields.name);
  fields.hasOwnProperty('visible') && body.append("si_document[visible]", fields.visible);

  return body;
};

export const isUserManager = (company, user) => {
  return user.user_id === company.user_id;
};

export const isUserAuthorised = (company, user) => {
  return user.authorised_companies.includes(company);
};

export const isUserInvestor = (company, user) => {
  return typeof getUserInvestment(company, user) !== 'undefined';
};

export const getUserInvestment = (company, user) => {
  if (!user.investment_details) return null;
  return user.investment_details.find(inv => inv.company_id === company.id);
};



