import axios from 'axios';
import {isEmptyString} from './validations';

export const pluralise = (num, one, many) => {
  return num === 1 ? one : many;
};

export const filenameFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/')+1);
};

// convert image urls to file objects
export const createFileFromUrl = async (url) => {
  const filename = filenameFromUrl(url);
  const resp = await axios.get(url, { responseType: 'blob' });
  return new File([resp.data], filename);
};

export const createFileFromBase64 = (dataUrl, filename) => {
  let arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type:mime });
};

export const getBase64FileSize = (base64Image) => {
  const head = 'data:image/jpeg;base64,';
  // If Base64=4/3*Original, then Original=3/4*Base64
  const imgFileSize = Math.round((base64Image.length - head.length)*3/4)/1024/1024;
  return imgFileSize;
};

export const getFileObjectSize = (fileObj) => {
  return fileObj?.size/1024/1024;
};

// "2022-07-08T15:46:35.703Z" => 2022/07/08
export const formatDateShort = (dateStr) => {
  const date = new Date(dateStr);
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };

  return date.toLocaleString('en-ZA', dateOptions);
};

export const friendlyDateOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

// "2022-07-08T15:46:35.703Z" => 8 July 2022
export const formatDateFriendly = (dateStr, shortMonth=false) => {
  const date = new Date(dateStr);
  return date.toLocaleString('en-ZA', {
    ...friendlyDateOptions,
    month: shortMonth ? 'short' : 'long'
  });
};

const regionNames = new Intl.DisplayNames(
  ['en'], { type: 'region' }
);

// 'za' => 'South Africa'
export const getCountryNameFromCode = (countryCode) => {
  try {
    return regionNames.of(countryCode);
  }
  catch(e) {
    return undefined;
  }
};

// Takes a generic error and decides if session has been expired
export const isSessionError = (e, router, addErrorMessage) => {
  if (e?.response?.status === 401) {
    addErrorMessage('Your session has expired', 'Please log in again');
    router.push('/login');
    return true;
  }

  return false;
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const getYearOptions = () => {
  const currYear = new Date().getFullYear();

  return Array(50)
    .fill(0)
    .map((_, i) => ({
      label: `${currYear - i}`,
      value: `${currYear - i}`,
    }));
};

