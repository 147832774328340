import axios from "axios";
import { buildUserFormData } from "../user";
import { isSessionError } from "../util";

export const getUser = async (api) => {
  const { host, authHeader } = api;

  try {
    const response = await axios.get(
      `${host}/users`,
      authHeader,
    );

    return response.data.user;
  }
  catch(e) {
    console.error('Error getting user', e);
  }
};

export const searchUsersByEmail = async (apiHost, authHeader, email, loadingFn, flash) => {
  const { addErrorMessage } = flash;

  try {
    loadingFn(true);
    const response = await axios.post(
      `${apiHost}/users/search?email=${email}`,
      null,
      authHeader,
    );

    loadingFn(false);
    return response.data.users;
  }
  catch(e) {
    loadingFn(false);
    addErrorMessage('User Search Failed', 'Something went wrong.');
  }
};

export const getInterestedCompanies = async (apiHost, authHeader) => {
  const response = await axios.get(
    `${apiHost}/interested_in`,
    authHeader,
  );

  const companies = response.data.interested_in_companies;

  return companies;
}

export const updateUser = async (fields, apiHost, authHeader, loadingFn, router, flash) => {
  const { addMessage, addErrorMessage } = flash;

  try {
    await axios.put(
      `${apiHost}/users`,
      buildUserFormData(fields),
      authHeader,
    );

    loadingFn(false);
    router.push(`/preferences`);
    addMessage('Updated User Successfully', 'Changes successfully applied.');

    // separate flash message to notify of password change
    if ((fields?.password) && (typeof fields.password !== undefined)) {
      addMessage('Password Updated Successfully', 'Please log in with new password.');
    }
  }
  catch(e) {
    loadingFn(false);
    if (!isSessionError(e, router, addErrorMessage))
      addErrorMessage('Update User Failed', 'Something went wrong.');
  }
};

