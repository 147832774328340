import {
  getCompany,
  createCompany,
  updateCompany,
  addLeadership,
  updateLeadership,
  removeLeadership,
  uploadDocument,
  deleteDocument,
  updateDocumentSection,
} from "../lib/client/company";

const { createContext, useContext, useReducer, useEffect } = require("react");

const Context = createContext();

const defaultState = {
  company: {
    id: null,
    name: "",
    address: "",
    country: "",
    city: "",
    suburb: "",
    website: "",
    bio: "",
    linkedin_url: "",
    twitter_url: "",
    images: [],
    description: "",
    pitchbook: null,
    currency: "",
    sectors: [],
    leaderships: [],
    documents: [],
    year_founded: "",
    authorisedUsers: [],
    created_user_role: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updateCompany":
      return {
        company: {
          ...state.company,
          ...action.value,
        },
      };
    case "updateCompanyField":
      return {
        company: {
          ...state.company,
          [action.field]: action.value,
        },
      };
    case "resetState":
      return defaultState;

    default:
      throw new Error();
  }
};

const Provider = ({ children, value }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    ...value
  });

  // update entire company when value changes
  useEffect(() => {
    dispatch({ type: "updateCompany", value });
  }, [value]);

  // update single company field
  const updateCompanyField = (field, value) => {
    dispatch({
      type: "updateCompanyField",
      field,
      value,
    });
  };

  const setCompany = (company, user, interestedInCompanies) => {
    let newCompany = { ...company };

    // append user permissions
    if (user && company) {
      newCompany = {
        ...newCompany,

        // user <=> company relationship
        isManagement: user?.user_id === company.user_id,
        isInvestor:
          typeof user?.investment_details?.find(
            (inv) => inv.company_id === company.id
          ) !== "undefined" || false,
        isInterested:
          interestedInCompanies &&
          typeof interestedInCompanies?.find((int) => int.id === company.id) !==
            "undefined",
      };
    }

    dispatch({ type: "updateCompany", value: newCompany });

    return newCompany;
  }

  const clearCompany = () => {
    dispatch({ type: "resetState" });
  };

  const exposed = {
    ...state,
    dispatch,
    setCompany,
    getCompany,
    createCompany,
    updateCompany,
    updateCompanyField,
    addLeadership,
    updateLeadership,
    removeLeadership,
    uploadDocument,
    deleteDocument,
    updateDocumentSection,
    clearCompany,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useCompany = () => useContext(Context);

export default Provider;
