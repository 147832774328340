import {
  getCompany,
  getAllCompanies,
  searchCompaniesByName,
  getCompanyBySlug,
  getAuthorisedUsers,
  removeAuthorisedUser,
  authoriseUser,
  inviteNewUserToCompany,
  sendGroupEmail,
  updateCompany,
  addCapitalRaise,
  updateCapitalRaise,
  removeCapitalRaise,
  uploadShareCertificate,
  uploadCapTable,
} from "../lib/client/company";
import { searchUsersByEmail, getUser } from "../lib/client/user";
const { createContext, useContext, useReducer } = require("react");

const Context = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "storeCompany":
      return {
        ...state,
        companies: {
          ...state.companies,
          [action.value.slug]: action.value,
        },
      };
    case "clearState":
      return {
        companies: {},
      };

    default:
      throw new Error();
  }
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    companies: {},
  });

  // user <=> company relationship
  const getUserPermissions = (company, user, interestedInCompanies) => {
    return {
      isManagement: user?.user_id === company.user_id,
      isInvestor:
      typeof user?.investment_details?.find(
        (inv) => inv.company_id === company.id
      ) !== "undefined" || false,
      isInterested:
      interestedInCompanies &&
      typeof interestedInCompanies?.find((int) => int.id === company.id) !==
      "undefined",
    };
  };

  // addCompanies
  const storeCompany = (company, user, interestedInCompanies) => {
    let newCompany = { ...company };

    // append user permissions
    if (user && company) {
      newCompany = {
        ...newCompany,
        ...getUserPermissions(company, user ,interestedInCompanies),
      };
    }
    dispatch({
      type: "storeCompany",
      value: newCompany,
    });

    return newCompany;
  };

  const clearDashboard = () => {
    dispatch({ type: "clearState" });
  };

  const getStoredCompany = slug => {
    const entry = Object.entries(state.companies).find((company) => {
      return slug === company[1].slug;
    });

    return entry ? entry[1] : undefined;
  };

  const exposed = {
    ...state,
    dispatch,
    storeCompany,
    getCompany,
    getCompanyBySlug,
    getAllCompanies,
    searchCompaniesByName,
    getAuthorisedUsers,
    removeAuthorisedUser,
    authoriseUser,
    inviteNewUserToCompany,
    sendGroupEmail,
    updateCompany,
    addCapitalRaise,
    updateCapitalRaise,
    removeCapitalRaise,
    uploadShareCertificate,
    uploadCapTable,
    getStoredCompany,
    clearDashboard,
    searchUsersByEmail,
    getUser,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useDashboard = () => useContext(Context);

export default Provider;
