
export const buildUserFormData = (fields) => {
  const { name, email, password, linkedIn, profilePic, interests, accepted } = fields;

  const body = new FormData();
  fields.hasOwnProperty('name') && body.append("user[full_name]", name);
  fields.hasOwnProperty('email') && body.append("user[email]", email);
  fields.hasOwnProperty('password') && password && body.append("user[password]", password);
  fields.hasOwnProperty('profilePic') && profilePic && body.append("user[profile_picture]", profilePic, profilePic.name);
  fields.hasOwnProperty('linkedIn') && body.append("user[linkedin_url]", linkedIn);
  fields.hasOwnProperty('accepted') && body.append("user[accepted_terms]", accepted);

  if (interests && interests.length) {
    interests.map((interest) => {
      body.append(`user[interests][]`, interest);
    });
  }

  return body;
};
